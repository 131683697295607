import { decryptSecret, unwrapEncryptedKeyPair, wrapKeyPair } from "@/features/cryptography";
import { store } from "@/store";
import { keysApi } from "@/store/services/supabase/endpoints/keys";
import { legacyKeysApi } from "@/store/services/supabase/endpoints/legacyKeys";

import { KeyPairAndSaltString } from "@/types/cryptography";
import { hexToArrayBuffer, stringToArrayBuffer } from "@/utils/crypto";
import { ERROR_TYPES } from "@/utils/hooks/useKeys/constants";
import {
  GetLegacyUserKeyFromDatabaseFn,
  GetOrgKeyFromDatabaseFn,
  GetUserKeyFromDatabaseFn,
  UnwrapKeyPairFnType,
} from "@/utils/hooks/useKeys/types";

export const unwrapKeyPair = async ({ data, password }: UnwrapKeyPairFnType) => {
  const salt = stringToArrayBuffer(data.salt);

  const { privateKey, publicKey } = await unwrapEncryptedKeyPair(
    {
      privateKey: stringToArrayBuffer(data.keyPair.privateKey),
      publicKey: stringToArrayBuffer(data.keyPair.publicKey),
    },
    password,
    salt
  );
  return {
    keyPair: {
      privateKey,
      publicKey,
    },
    salt,
  };
};

const okRes = <T>(data: T): { data: T; error: null } => ({
  data,
  error: null,
});

const errRes = (error: { type: string; message: string }) => ({
  data: null,
  error,
});

export const getLegacyUserKeyFromDatabase: GetLegacyUserKeyFromDatabaseFn = async ({ password }) => {
  const { data, error } = await store.dispatch(legacyKeysApi.endpoints.getLegacyKeyPair.initiate());

  if (!data?.keyPair) {
    return errRes({
      type: ERROR_TYPES.EMPTY_RESPONSE,
      message: "",
    });
  }

  if (error) {
    return errRes({
      type: "",
      message: error && "error" in error ? error.error : "Unable to fetch legacy user keys",
    });
  }

  try {
    const salt = hexToArrayBuffer(data.salt);
    const keyPair = await unwrapEncryptedKeyPair(
      {
        privateKey: hexToArrayBuffer(data.keyPair.privateKey),
        publicKey: hexToArrayBuffer(data.keyPair.publicKey),
      },
      password,
      salt
    );

    const encryptedKeyPair = await wrapKeyPair({
      keyPair,
      salt,
      secret: password,
    });

    return okRes(encryptedKeyPair);
  } catch (err) {
    return errRes({
      type: ERROR_TYPES.INCORRECT_PASSWORD,
      message: "Unable to decrypt your portfolios - your password may be incorrect.",
    });
  }
};

export const getUserKeyFromDatabase: GetUserKeyFromDatabaseFn = async ({ password, forceRefetch }) => {
  const { data, error } = await store.dispatch(
    keysApi.endpoints.getUserKeyPair.initiate(undefined, {
      forceRefetch,
    })
  );

  if (!data?.public_key || !data?.keys_enc) {
    return errRes({
      type: ERROR_TYPES.EMPTY_RESPONSE,
      message: "",
    });
  }

  if (error) {
    return errRes({
      type: "",
      message: error && "error" in error ? error.error : "Unable to fetch user keys",
    });
  }

  try {
    let orgSecret = null;
    const orgSecretEnc = data.users_groups[0].org_secret_enc;

    const unwrapped = await unwrapKeyPair({
      data: JSON.parse(data.keys_enc as string) as KeyPairAndSaltString,
      password,
    });

    if (orgSecretEnc) {
      try {
        orgSecret = await decryptSecret({
          privateKey: unwrapped.keyPair.privateKey,
          secret: stringToArrayBuffer(orgSecretEnc),
        });
      } catch {
        console.log("Org secret cannot be decrypted.");
      }
    } else {
      console.log("Org secret not found.");
    }

    return okRes({
      ...unwrapped,
      orgSecret,
    });
  } catch (err) {
    return errRes({
      type: ERROR_TYPES.INCORRECT_PASSWORD,
      message: "Unable to decrypt your portfolios - your password may be incorrect.",
    });
  }
};

export const getOrgKeyFromDatabase: GetOrgKeyFromDatabaseFn = async ({ secret, forceRefetch }) => {
  const { data, error } = await store.dispatch(
    keysApi.endpoints.getOrgKeyPair.initiate(undefined, {
      forceRefetch,
    })
  );

  if (!data?.public_key || !data?.keys_enc) {
    return errRes({
      type: ERROR_TYPES.EMPTY_RESPONSE,
      message: "",
    });
  }

  if (error) {
    return errRes({
      type: ERROR_TYPES.FETCH_ERROR,
      message: error && "error" in error ? error.error : "Unable to fetch org keys",
    });
  }

  try {
    const parsedData = JSON.parse(data.keys_enc as string) as KeyPairAndSaltString;
    const unwrapped = await unwrapKeyPair({ data: parsedData, password: secret });

    return okRes(unwrapped);
  } catch {
    return errRes({
      type: ERROR_TYPES.INCORRECT_PASSWORD,
      message: "Something went wrong. Your secret may be incorrect.",
    });
  }
};

export const getOrgSecretFromDatabase = async ({ privateKey }: { privateKey: CryptoKey }) => {
  const { data, error } = await store.dispatch(
    keysApi.endpoints.getUserOrgSecret.initiate({} as any, { forceRefetch: true })
  );

  if (!data?.org_secret_enc) {
    return errRes({
      type: ERROR_TYPES.EMPTY_RESPONSE,
      message: "Org secret not found",
    });
  }

  if (error) {
    return errRes({
      type: ERROR_TYPES.FETCH_ERROR,
      message: error && "error" in error ? error.error : "Something went wrong. Please try again.",
    });
  }

  try {
    const decryptedSecret = await decryptSecret({
      privateKey,
      secret: stringToArrayBuffer(data.org_secret_enc),
    });
    return okRes(decryptedSecret);
  } catch {
    return errRes({
      type: ERROR_TYPES.INVALID_KEY_OR_SECRET,
      message: "Unable to decrypt org secret",
    });
  }
};

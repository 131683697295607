import styles from "@/components/authentication/SignUp.module.scss";
import { getConfig } from "@/features/branding";
import { UploadOutlined } from "@ant-design/icons";
import { fetchFileUrlFromHubspotViaProxy, HUBSPOT_FORM_UPLOADS_FOLDER_ID, sendDataToFormViaProxy, sendUploadedFileToHubspotViaProxy } from "../apis";
import { PublicContactSupportFormProps } from "@/features/support";
import { Alert, Button, Form, Input, notification, Typography, Upload } from "antd";
import { UploadFile } from "antd/lib";
import Link from "next/link";
import { useSearchParams } from "next/navigation";
import { useState } from "react";
import { useTranslation } from "react-i18next";
const {
  Title
} = Typography;
const {
  TextArea
} = Input;
const NON_LOGGED_IN_SUPPORT_FORM = "ad2bc3ab-3fa0-4388-b89c-a298612bfe7a";
const MAX_FILE_SIZE_MB = 2;
export const PublicXdiSupportForm = () => {
  const urlParams = useSearchParams();
  const [form] = Form.useForm();
  const {
    t
  } = useTranslation("pages", {
    keyPrefix: "contactSupport"
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [notify, notificationContext] = notification.useNotification();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const uploadFile = async (file: any) => {
    try {
      const uploadData = await sendUploadedFileToHubspotViaProxy({
        file,
        folderId: HUBSPOT_FORM_UPLOADS_FOLDER_ID,
        options: {
          access: "PRIVATE",
          ttl: "P1D"
        }
      });
      if (uploadData?.status == "error") {
        throw new Error();
      }
      const signedUrl = await fetchFileUrlFromHubspotViaProxy(uploadData?.id);
      if (signedUrl?.status == "error") {
        throw new Error();
      }
      return signedUrl?.url;
    } catch (err) {
      const msg = t("form.errorMessage");
      throw new Error(msg);
    }
  };
  const handleSubmit = async (values: PublicContactSupportFormProps) => {
    setIsSubmitting(true);
    setErrorMsg("");
    try {
      let fileObj;
      if (values.file_upload) {
        const uploadedFilesUrls = await Promise.all(values.file_upload?.map((file: any) => uploadFile(file)));
        fileObj = uploadedFilesUrls.length ? {
          file_upload: uploadedFilesUrls
        } : {};
      }
      const res = await sendDataToFormViaProxy({
        formId: NON_LOGGED_IN_SUPPORT_FORM,
        data: {
          firstname: values.username,
          username: values.username,
          email: values.username,
          organisation: values.organisation || "",
          message: values.message,
          ...fileObj,
          support_source_url: urlParams.get("sourceUrl") || "",
          xdi_support_platform: "XDI Hub",
          xdi_support_issue_type: urlParams.get("issueType") || "",
          xdi_support_sla: "24 hours"
        }
      });
      if (res?.status == "error") {
        throw new Error();
      }
      form.resetFields();
      notify.success({
        message: "Your enquiry was submitted!",
        description: "We will get in touch with you shortly.",
        duration: 10
      });
      setIsSubmitting(false);
    } catch (err: any) {
      console.log(err);
      setErrorMsg("message" in err ? err.message : t("form.errorMessage"));
      setIsSubmitting(false);
    }
  };
  return <div style={{
    height: "auto",
    width: 600
  }} data-sentry-component="PublicXdiSupportForm" data-sentry-source-file="PublicXdiSupportForm.tsx">
      {notificationContext}
      <Title level={1} className={styles.title} data-sentry-element="Title" data-sentry-source-file="PublicXdiSupportForm.tsx">
        Having trouble logging in, or have an enquiry?
      </Title>
      <br />
      <Form form={form} layout="vertical" requiredMark={false} style={{
      height: "auto",
      width: 600
    }} className={styles.form} onFinish={handleSubmit} data-sentry-element="Form" data-sentry-source-file="PublicXdiSupportForm.tsx">
        <Form.Item name="username" label={<div>
              <label>{t("form.username")}</label>
              <div className="text-xs text-gray-400">
                Please enter your work email address associated to your XDI account
              </div>
            </div>} rules={[{
        required: true,
        message: "Please enter your email or username"
      }]} data-sentry-element="unknown" data-sentry-source-file="PublicXdiSupportForm.tsx">
          <Input data-sentry-element="Input" data-sentry-source-file="PublicXdiSupportForm.tsx" />
        </Form.Item>

        <Form.Item name="organisation" label={t("form.organisation")} data-sentry-element="unknown" data-sentry-source-file="PublicXdiSupportForm.tsx">
          <Input data-sentry-element="Input" data-sentry-source-file="PublicXdiSupportForm.tsx" />
        </Form.Item>

        <Form.Item rules={[{
        required: true,
        message: "Please enter a message"
      }]} name="message" label={<div>
              <label>{t("form.message")}</label>
              <div className="text-xs text-gray-400">
                Please explain any issues that you are facing in logging into the XDI Client Risk Hub
              </div>
            </div>} data-sentry-element="unknown" data-sentry-source-file="PublicXdiSupportForm.tsx">
          <TextArea rows={3} data-sentry-element="TextArea" data-sentry-source-file="PublicXdiSupportForm.tsx" />
        </Form.Item>
        <Form.Item name="file_upload" label="File Upload" valuePropName="fileList" getValueFromEvent={(e: any) => e.fileList} rules={[{
        validator: (_: any, fileList: UploadFile[]) => {
          if (!fileList) {
            return Promise.resolve();
          }
          const invalidFiles = fileList.filter(file => file.size! / 1024 / 1024 > MAX_FILE_SIZE_MB);
          if (invalidFiles.length > 0) {
            return Promise.reject(new Error(`One or more files exceed the ${MAX_FILE_SIZE_MB}MB size limit.`));
          }
          return Promise.resolve();
        }
      }]} data-sentry-element="unknown" data-sentry-source-file="PublicXdiSupportForm.tsx">
          <Upload multiple beforeUpload={() => false} data-sentry-element="Upload" data-sentry-source-file="PublicXdiSupportForm.tsx">
            <Button icon={<UploadOutlined />} data-sentry-element="Button" data-sentry-source-file="PublicXdiSupportForm.tsx">Upload</Button>
          </Upload>
        </Form.Item>
        <p className="mb-3 mt-3 text-sm">
          XDI (Cross Dependency Initiative) needs the contact information you provide to contact you about our products
          and services.
        </p>
        <p className="mb-3 text-sm">You may unsubscribe from these communications at any time.</p>
        <p className="mb-3 text-sm">
          For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your
          privacy, please review our{" "}
          <Link href={getConfig().featureSet.docs.legal.privacyPolicy} style={{
          textDecoration: "underline"
        }} target="_blank" rel="noreferrer" data-sentry-element="Link" data-sentry-source-file="PublicXdiSupportForm.tsx">
            {t("privacyPolicy")}
          </Link>
          .
        </p>
        {errorMsg && <Alert type="error" message={errorMsg} className={styles.alert} />}
        <Form.Item data-sentry-element="unknown" data-sentry-source-file="PublicXdiSupportForm.tsx">
          <Button type="primary" block htmlType="submit" loading={isSubmitting} data-sentry-element="Button" data-sentry-source-file="PublicXdiSupportForm.tsx">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>;
};
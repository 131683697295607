import { encryptSecret, exportKey, generateRandomSecret, generateWrappedKeyPair } from "@/features/cryptography";
import {
  useAddOrgKeyPairMutation,
  useAddUserKeyPairMutation,
  useAddUserOrgSecretMutation,
  useLazyCanGenerateOrgKeysQuery,
} from "@/store/services/supabase/endpoints/keys";
import { arrayBufferToString } from "@/utils/crypto";
import { ERROR_TYPES, KEY_PAIR_STORE } from "@/utils/hooks/useKeys/constants";
import {
  getLegacyUserKeyFromDatabase,
  getOrgKeyFromDatabase,
  getOrgSecretFromDatabase,
  getUserKeyFromDatabase,
} from "@/utils/hooks/useKeys/helper";
import { KeyPairContext } from "@/utils/hooks/useKeys/KeyPairProvider";
import {
  GenerateUserKeysFn,
  GetAllKeysFn,
  GetOrGenerateFn,
  GetOrgKeysFn,
  GetUserKeysFn,
  TransferLegacyUserKeysFn,
  UseKeysType,
} from "@/utils/hooks/useKeys/types";
import { setItemInSessionStorage, setKeysInSessionStorage } from "@/utils/hooks/useKeys/utils";
import { useContext } from "react";

export const useKeys = (): UseKeysType => {
  const [addUserKeyPair] = useAddUserKeyPairMutation();
  const [addOrgKeyPair] = useAddOrgKeyPairMutation();
  const [addUserOrgSecret] = useAddUserOrgSecretMutation();
  const [canGenerateOrgKeys] = useLazyCanGenerateOrgKeysQuery();

  const ctx = useContext(KeyPairContext);

  if (!ctx) {
    throw new Error("useKeyPair must be used within a KeyPairProvider");
  }

  const transferLegacyUserKeys: TransferLegacyUserKeysFn = async ({ keyPair, encryptedKeyPair, salt }) => {
    await addUserKeyPair({
      keys_enc: JSON.stringify({
        keyPair: {
          publicKey: arrayBufferToString(encryptedKeyPair.publicKey),
          privateKey: arrayBufferToString(encryptedKeyPair.privateKey),
        },
        salt: arrayBufferToString(salt),
      }),
      public_key: JSON.stringify(await exportKey(keyPair.publicKey)),
    })
      .unwrap()
      .then((res) => {
        console.log("User key pair transferred");
        return res;
      })
      .catch((err) => {
        console.error("Error transferring legacy user keys", JSON.stringify(err));
        return {
          data: null,
          error: {
            type: "",
            message: "Error transferring user keys. Please try again.",
          },
        };
      });

    await setKeysInSessionStorage(KEY_PAIR_STORE.user, {
      keyPair,
      salt,
    });

    await ctx.refetch();

    return {
      data: {
        keyPair,
        salt,
      },
      error: null,
    };
  };

  const getUserKeys: GetUserKeysFn = async ({ password, forceRefetch }) => {
    if (ctx.user) {
      return {
        data: ctx.user,
        error: null,
      };
    }

    const { data, error } = await getUserKeyFromDatabase({ password, forceRefetch });

    if (data) {
      await setKeysInSessionStorage(KEY_PAIR_STORE.user, data);
      if (data.orgSecret) {
        await setItemInSessionStorage(KEY_PAIR_STORE.orgSecret, data.orgSecret);
      }
      await ctx.refetch();

      return {
        data,
        error,
      };
    }

    return {
      data,
      error,
    };
  };

  const getOrgKeys: GetOrgKeysFn = async ({ secret, forceRefetch }) => {
    if (ctx.org)
      return {
        data: ctx.org,
        error: null,
      };

    const { data, error } = await getOrgKeyFromDatabase({ secret, forceRefetch });

    if (data) {
      await setKeysInSessionStorage(KEY_PAIR_STORE.org, data);
      await ctx.refetch();

      return {
        data,
        error,
      };
    }

    return {
      data,
      error,
    };
  };

  const getOrgSecret = async ({ privateKey }: { privateKey: CryptoKey }) => {
    console.log("getOrgSecret.privateKey", privateKey);

    if (ctx.orgSecret) {
      console.log("hits context: ", ctx.orgSecret);
      return {
        data: ctx.orgSecret,
        error: null,
      };
    }

    const { data, error } = await getOrgSecretFromDatabase({ privateKey });

    if (data) {
      await setItemInSessionStorage(KEY_PAIR_STORE.orgSecret, data);
      await ctx.refetch();

      return {
        data,
        error,
      };
    }

    return {
      data,
      error,
    };
  };

  const generateUserKeys: GenerateUserKeysFn = async ({ password }) => {
    const pairs = await generateWrappedKeyPair({ secret: password });

    await addUserKeyPair({
      keys_enc: JSON.stringify({
        keyPair: {
          publicKey: arrayBufferToString(pairs.encryptedKeyPair.publicKey),
          privateKey: arrayBufferToString(pairs.encryptedKeyPair.privateKey),
        },
        salt: arrayBufferToString(pairs.salt),
      }),
      public_key: JSON.stringify(await exportKey(pairs.keyPair.publicKey)),
    })
      .unwrap()
      .then((res) => {
        console.log("User key pair added");
        return res;
      })
      .catch((err) => {
        console.error("Error generating user keys: ", JSON.stringify(err));
        return {
          data: null,
          error: {
            type: "",
            message: "Error generating user keys. Please try again.",
          },
        };
      });

    await setKeysInSessionStorage(KEY_PAIR_STORE.user, {
      keyPair: pairs.keyPair,
      salt: pairs.salt,
    });

    await ctx.refetch();

    return {
      data: {
        keyPair: pairs.keyPair,
        salt: pairs.salt,
      },
      error: null,
    };
  };

  const generateOrgKeys = async ({ userKeyPair }: { userKeyPair: CryptoKeyPair }) => {
    const secret = generateRandomSecret();
    const pairs = await generateWrappedKeyPair({ secret });
    const encryptedSecret = await encryptSecret({ publicKey: userKeyPair.publicKey, secret });

    await addUserOrgSecret({ org_secret_enc: arrayBufferToString(encryptedSecret) });
    await setItemInSessionStorage(KEY_PAIR_STORE.orgSecret, secret);

    try {
      await addOrgKeyPair({
        public_key: JSON.stringify(await exportKey(pairs.keyPair.publicKey)),
        keys_enc: JSON.stringify({
          keyPair: {
            publicKey: arrayBufferToString(pairs.encryptedKeyPair.publicKey),
            privateKey: arrayBufferToString(pairs.encryptedKeyPair.privateKey),
          },
          salt: arrayBufferToString(pairs.salt),
        }),
      })
        .unwrap()
        .then((res) => {
          console.log("Org key pair added");
          return res;
        })
        .catch((error) => {
          if (error.error === "UNAUTHORIZED") {
            return {
              data: null,
              error,
            };
          }

          console.error("Error generating org keys: ", JSON.stringify(error));
          return {
            data: null,
            error: {
              type: "",
              message: "Error generating user keys. Please try again.",
            },
          };
        });

      await setKeysInSessionStorage(KEY_PAIR_STORE.org, {
        keyPair: pairs.keyPair,
        salt: pairs.salt,
      });

      return {
        data: {
          keyPair: pairs.keyPair,
          salt: pairs.salt,
        },
        error: null,
      };
    } catch (err) {
      console.error(err);
    }
  };

  const getAllKeys: GetAllKeysFn = async ({ password }) => {
    ctx.setLoading(true);

    try {
      const userRes = await getUserKeys({ password, forceRefetch: true });
      if (userRes.error) throw new Error(userRes.error.message);

      const user = userRes.data;
      if (!user?.keyPair || !user?.salt) throw new Error("User key pair not found");

      const orgSecretRes = await getOrgSecret({ privateKey: user.keyPair.privateKey });
      if (orgSecretRes.error) throw new Error(orgSecretRes.error.message);

      const orgSecret = orgSecretRes.data;
      if (!orgSecret) console.log("Org secret not found");

      const orgRes = await getOrgKeys({ secret: orgSecret || "", forceRefetch: true });
      if (orgRes.error) throw new Error(orgRes.error.message);

      const org = orgRes.data;
      if (!org?.keyPair || !org?.salt) throw new Error("Org key pair not found");

      return {
        data: { user, org, orgSecret },
        error: null,
      };
    } catch (error: any) {
      console.error("Error fetching keys:", error.message);
      return {
        data: { user: null, org: null, orgSecret: null },
        error: {
          type: "ERROR",
          message: error.message || "An unknown error occurred",
        },
      };
    } finally {
      console.log("getAllKeys.finally");
      await ctx.refetch();
      ctx.setLoading(false);
    }
  };

  const getOrGenerate: GetOrGenerateFn = async ({ password }) => {
    const { data: canGenerate, error: canGenerateErr } = await canGenerateOrgKeys();
    let keys = await ctx.refetch();

    if (keys.user && keys.org && keys.orgSecret) {
      return;
    }

    if (!keys.user) {
      console.log("%cF[USER] Fetching keys", "background: green; color: white");

      const userRes = await getUserKeys({ password, forceRefetch: true });
      if (userRes.error?.type === ERROR_TYPES.EMPTY_RESPONSE) {
        console.log("%c[USER] Not found", "background: yellow; color: black");
        console.log("%cF[USER] Fetching legacy user keys", "background: green; color: white");

        const { data, error } = await getLegacyUserKeyFromDatabase({ password });

        if (data) {
          console.log("%cF[USER] Transferring legacy user keys", "background: green; color: white");
          await transferLegacyUserKeys(data);
        }

        if (error) {
          console.log("%c[USER] Legacy keys not found. Generating...", "background: yellow; color: black");
          await generateUserKeys({ password });
        }
      }

      keys = await ctx.refetch();
    }

    if (!canGenerate || canGenerateErr) {
      console.log("%[ORG] Fetching keys", "background: green; color: white");

      const privateKey = keys.user?.keyPair?.privateKey;
      const orgSecret = privateKey ? await getOrgSecret({ privateKey }) : null;
      if (orgSecret && orgSecret.data) {
        await getOrgKeys({ secret: orgSecret.data });
      }

      await ctx.refetch();
      return;
    }

    if (keys.user && canGenerate) {
      console.log("%c[ORG] Not found. Generating...", "background: yellow; color: black");
      await generateOrgKeys({ userKeyPair: keys.user.keyPair });
    }

    await ctx.refetch();
  };

  return [ctx, { getOrGenerate, getAllKeys, generateUserKeys }];
};

import { dealiasArchetype, getArchetypeByValue, getRiskBandsMode } from "@/features/branding";
import { store } from "@/store";
import { abcFacadesApi } from "@/store/services/facades/endpoints/abc";
import { haloFacadesApi } from "@/store/services/facades/endpoints/halo";
import { simpleFacadesApi } from "@/store/services/facades/endpoints/simple";
import { RiskBandsRequest } from "@/store/services/facades/types";
import { AbcAcronym } from "@/store/services/facades/types/abc";
import { SimpleCommercialAcronym, SimpleResidentialAcronym } from "@/store/services/facades/types/simple";
import { HaloAcronym } from "@climaterisk/facades_ts/halo_request";

export const getRiskBands = (requestData: RiskBandsRequest) => {
  switch (getRiskBandsMode()) {
    case "halo":
      return store.dispatch(
        haloFacadesApi.endpoints.getHaloRiskBands.initiate({
          ...requestData,
          archetype: dealiasArchetype<HaloAcronym>(requestData.archetype),
        })
      );

    case "abc":
      return store.dispatch(
        abcFacadesApi.endpoints.getAbcRiskBands.initiate({
          ...requestData,
          archetype: dealiasArchetype<AbcAcronym>(requestData.archetype),
        })
      );

    case "simple":
      return getSimpleStructuralDispatch(requestData);
  }
};

const getSimpleStructuralDispatch = (requestData: RiskBandsRequest) => {
  const { category } = getArchetypeByValue(requestData.archetype!)!;

  switch (category) {
    case "commercial":
    case "industrial":
      return store.dispatch(
        simpleFacadesApi.endpoints.getSimpleCommercialRiskBands.initiate({
          ...requestData,
          archetype: dealiasArchetype<SimpleCommercialAcronym>(requestData.archetype),
        })
      );

    case "residential":
      return store.dispatch(
        simpleFacadesApi.endpoints.getSimpleResidentialRiskBands.initiate({
          ...requestData,
          archetype: dealiasArchetype<SimpleResidentialAcronym>(requestData.archetype),
        })
      );
  }
};

import styles from "@/components/authentication/SignUp.module.scss";
import { FeedbackFormProps } from "@/features/support";
import { sendDataToFormViaProxy } from "../apis";
import { useGetCurrentUserQuery } from "@/store/services/supabase";
import { Alert, Button, Form, Input, Modal, notification, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
const {
  Title
} = Typography;
const {
  TextArea
} = Input;
const FEEDBACK_FORM = "f5641b40-a6ac-477b-9abf-d11df96709b3";
export const XdiFeedbackForm = ({
  isOpen,
  onClose
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [form] = Form.useForm();
  const {
    data: currentUser
  } = useGetCurrentUserQuery();
  const {
    t
  } = useTranslation("features", {
    keyPrefix: "feedback"
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [notify, notificationContext] = notification.useNotification();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async (values: FeedbackFormProps) => {
    setIsSubmitting(true);
    setErrorMsg("");
    try {
      const res = await sendDataToFormViaProxy({
        formId: FEEDBACK_FORM,
        data: {
          firstname: values.firstname || values.username,
          lastname: values.lastname || "",
          username: values.username,
          email: values.username,
          organisation: values.organisation || "",
          message: values.message,
          xdi_support_platform: "XDI Hub",
          xdi_support_issue_type: "Feedback & Feature Requests"
        }
      });
      if (res?.status == "error") {
        throw new Error();
      }
      form.resetFields();
      notify.success({
        message: t("form.successMessage.title"),
        description: t("form.successMessage.description"),
        duration: 0
      });
      onClose();
      setIsSubmitting(false);
    } catch (err: any) {
      console.log(err);
      setErrorMsg("message" in err ? err.message : t("form.errorMessage"));
      setIsSubmitting(false);
    }
  };
  return <Modal width={600 + 48} footer={null} open={isOpen} onCancel={() => {
    form.resetFields();
    onClose();
  }} data-sentry-element="Modal" data-sentry-component="XdiFeedbackForm" data-sentry-source-file="XdiFeedbackForm.tsx">
      <div style={{
      height: "auto",
      width: 600
    }}>
        {notificationContext}
        <Title level={1} className={styles.title} data-sentry-element="Title" data-sentry-source-file="XdiFeedbackForm.tsx">
          {t("title")}
        </Title>
        <br />
        <Form form={form} layout="vertical" requiredMark={false} style={{
        height: "auto",
        width: 600
      }} className={styles.form} onFinish={handleSubmit} initialValues={{
        username: currentUser?.email,
        organisation: currentUser?.org_name
      }} data-sentry-element="Form" data-sentry-source-file="XdiFeedbackForm.tsx">
          <div className="grid grid-cols-6 gap-x-4">
            <Form.Item className="col-span-3" name="firstname" label={t("form.firstname")} data-sentry-element="unknown" data-sentry-source-file="XdiFeedbackForm.tsx">
              <Input data-sentry-element="Input" data-sentry-source-file="XdiFeedbackForm.tsx" />
            </Form.Item>
            <Form.Item className="col-span-3" name="lastname" label={t("form.lastname")} data-sentry-element="unknown" data-sentry-source-file="XdiFeedbackForm.tsx">
              <Input data-sentry-element="Input" data-sentry-source-file="XdiFeedbackForm.tsx" />
            </Form.Item>
          </div>
          <Form.Item name="username" label={<div>
                <label>{t("form.username")}</label>
                <div className="text-xs text-gray-400">
                  This should be the email address associated to your XDI account
                </div>
              </div>} rules={[{
          required: true,
          message: "Please enter your email or username"
        }]} data-sentry-element="unknown" data-sentry-source-file="XdiFeedbackForm.tsx">
            <Input disabled={!!currentUser?.email} data-sentry-element="Input" data-sentry-source-file="XdiFeedbackForm.tsx" />
          </Form.Item>

          <Form.Item rules={[{
          required: true,
          message: "Please enter your organisation"
        }]} name="organisation" label={<div>
                <label>{t("form.organisation")}</label>
                <div className="text-xs text-gray-400">
                  This should be the Organisation under which your Hub account is registered
                </div>
              </div>} data-sentry-element="unknown" data-sentry-source-file="XdiFeedbackForm.tsx">
            <Input disabled={!!currentUser?.org_name} data-sentry-element="Input" data-sentry-source-file="XdiFeedbackForm.tsx" />
          </Form.Item>

          <Form.Item rules={[{
          required: true,
          message: "Please enter a message"
        }]} name="message" label={<div>
                <label>{t("form.message")}</label>
                <div className="text-xs text-gray-400">
                  Please provide detailed feedback on how we might improve the Hub experience to better support your
                  physical climate risk needs
                </div>
              </div>} data-sentry-element="unknown" data-sentry-source-file="XdiFeedbackForm.tsx">
            <TextArea rows={3} data-sentry-element="TextArea" data-sentry-source-file="XdiFeedbackForm.tsx" />
          </Form.Item>
          {errorMsg && <Alert type="error" message={errorMsg} className={styles.alert} />}
          <Form.Item data-sentry-element="unknown" data-sentry-source-file="XdiFeedbackForm.tsx">
            <Button type="primary" block htmlType="submit" loading={isSubmitting} data-sentry-element="Button" data-sentry-source-file="XdiFeedbackForm.tsx">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>;
};
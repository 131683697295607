import Script from "next/script";
import type { MetabaseDashboardProps } from "../../types";
import { IFrame } from "./components/IFrame";
export const MetabaseDashboard = (props: MetabaseDashboardProps) => {
  const namespace = props.namespace || "the-climate-risk-group";
  return <>
      <Script src={`https://${namespace}.metabaseapp.com/app/iframeResizer.js`} data-sentry-element="Script" data-sentry-source-file="MetabaseDashboard.tsx" />
      <IFrame src={`https://${namespace}.metabaseapp.com/public/dashboard/${props.id}`} onLoad={() => {
      if ("iFrameResize" in global) {
        const iFrameResize = global.iFrameResize as (a: unknown, b: unknown) => void;
        iFrameResize({}, this);
      }
    }} data-sentry-element="IFrame" data-sentry-source-file="MetabaseDashboard.tsx" />
    </>;
};
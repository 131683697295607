import { CommsModal } from "@/components/overlays/CommsModal";
import { getCommsConfig } from "@/features/branding";
import { XdiFeedbackForm, XdiSupportForm } from "@/features/support";
export type CommsModalControllerMode = "none" | "support" | "feedback";
export interface CommsModalControllerProps {
  mode: CommsModalControllerMode;
  onClose: () => void;
}
export const CommsModalController = ({
  mode,
  onClose
}: CommsModalControllerProps) => {
  const {
    feedback,
    support
  } = getCommsConfig();
  return process.env.NEXT_PUBLIC_THEME_NAME?.startsWith("xdi") ? <>
      {support.enabled && <XdiSupportForm isOpen={mode == "support"} onClose={onClose} />}
      {feedback.enabled && <XdiFeedbackForm isOpen={mode == "feedback"} onClose={onClose} />}
    </> : <>
      {feedback.enabled && <CommsModal mode="feedback" open={mode === "feedback"} onCancel={onClose} />}
      {support.enabled && <CommsModal mode="support" open={mode === "support"} onCancel={onClose} />}
    </>;
};
import { defaultSimpleStructuralRiskBandsConfig } from "@/features/risk_bands";
import portfolioExport from "@/tools/aggregate/portfolio-export";
import { allTools } from "@/tools/all_tools";
import { HubThemePartial } from "../../types";
import { XdiHomeDashboard } from "./components/XdiHomeDashboard";

const xdiThemeBase: HubThemePartial = {
  meta: {
    name: "XDI Climate Risk Hub",
    hasFontStyles: true,
    config: {
      featureSet: {
        comms: {
          support: { enabled: true },
          feedback: {
            enabled: true,
          },
          reseller: {
            ticketRequestForm: {
              enabled: true,
            },
          },
        },
        docs: {
          contextualElementTutorials: {
            enabled: true,
          },
          knowledgeBase: {
            glossary: {
              enabled: true,
            },
            modelling: {
              enabled: true,
              publicDocsUrl: "https://learn.xdi.systems/docs/climate-and-hazard-modelling ",
            },
            changeLog: {
              enabled: true,
            },
            faqs: {
              enabled: true,
            },
          },
          toolPageTutorials: {
            enabled: true,
          },
        },
        settings: {
          enabled: true,
        },
        tools: [
          ...allTools.filter(({ keyPrefix }) => keyPrefix !== "aggregate.blaze"),
          {
            ...portfolioExport,
            hasTutorial: false,
          },
        ],
        tracking: {
          hubspot: {
            enabled: true,
            allowEmbedCode: false,
            allowToolTracking: true,
            pipeline: {
              id: "49578663", // "Hub Usage Pipeline"
              stage: "101746048", // "Hub Analysis Done (Hub Usage Pipeline)""
            },
          },
          plausible: {
            enabled: true,
            allowLocalTracking: false,
            siteDomain: "app.climaterisk.net",
          },
        },
        widgets: {
          allowDocument360Widget: true,
        },
      },
      locale: {
        defaultLanguage: "en-AU",
      },
      pages: {
        home: XdiHomeDashboard,
      },
      riskBands: defaultSimpleStructuralRiskBandsConfig,
      portfolioExport: {
        largeBank: {
          minAddresses: 20,
        },
      },
    },
    logoUrl: "/xdi-logo.png",
    logoIconUrl: "/xdi-logo-icon.png",
    backgroundImage: "/xdi-background.svg",
    backgroundColor: "#454545",
  },
  antd: {
    token: {
      fontFamily:
        "'Figtree',-apple-system,BlinkMacSystemFont,'Segoe UI Adjusted','Segoe UI','Liberation Sans',sans-serif",
      colorPrimary: "#D92028",
      colorText: "#373736",
    },
    components: {
      Layout: {
        headerBg: "#f5f5f5",
      },
    },
    // "secondary-color": "#585d63",
  },
};

export default xdiThemeBase;

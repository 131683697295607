import styles from "@/components/authentication/SignUp.module.scss";
import { ContactSupportFormProps } from "@/features/support";
import { UploadOutlined } from "@ant-design/icons";
import { fetchFileUrlFromHubspotViaProxy, HUBSPOT_FORM_UPLOADS_FOLDER_ID, sendDataToFormViaProxy, sendUploadedFileToHubspotViaProxy } from "../apis";
import { useGetCurrentUserQuery } from "@/store/services/supabase";
import { Alert, Button, Form, Input, Modal, notification, Select, Typography, Upload } from "antd";
import { UploadFile } from "antd/lib";
import { useState } from "react";
import { useTranslation } from "react-i18next";
const {
  Title
} = Typography;
const {
  TextArea
} = Input;
const LOGGED_IN_SUPPORT_FORM = "bddcbc54-89b9-4944-a790-3041c88d08ca";
const MAX_FILE_SIZE_MB = 2;
const issueTypeOptions = [{
  value: "Technical Issues & Bugs",
  description: "Reporting system errors, glitches, or unexpected behavior in the platform."
}, {
  value: "Unexpected Data & Results",
  description: "Questions about analysis outputs, discrepancies, or interpretation of climate risk data."
}, {
  value: "Account & Access Management",
  description: "Issues related to login, permissions, multi-factor authentication (MFA), or onboarding."
}, {
  value: "Feedback & Feature Requests",
  description: "Suggestions for platform improvements, new features, or enhancements to existing tools."
}, {
  value: "Other",
  description: "Anything else"
}];
export const XdiSupportForm = ({
  isOpen,
  onClose
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [form] = Form.useForm();
  const {
    data: currentUser
  } = useGetCurrentUserQuery();
  const {
    t
  } = useTranslation("features", {
    keyPrefix: "support"
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [notify, notificationContext] = notification.useNotification();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const uploadFile = async (file: any) => {
    try {
      const uploadData = await sendUploadedFileToHubspotViaProxy({
        file,
        folderId: HUBSPOT_FORM_UPLOADS_FOLDER_ID,
        options: {
          access: "PRIVATE",
          ttl: "P1D"
        }
      });
      if (uploadData?.status == "error") {
        throw new Error();
      }
      const signedUrl = await fetchFileUrlFromHubspotViaProxy(uploadData?.id);
      if (signedUrl?.status == "error") {
        throw new Error();
      }
      return signedUrl?.url;
    } catch (err) {
      const msg = t("form.errorMessage");
      throw new Error(msg);
    }
  };
  const handleSubmit = async (values: ContactSupportFormProps) => {
    setIsSubmitting(true);
    setErrorMsg("");
    try {
      let fileObj;
      if (values.file_upload) {
        const uploadedFilesUrls = await Promise.all(values.file_upload?.map((file: any) => uploadFile(file)));
        fileObj = uploadedFilesUrls.length ? {
          file_upload: uploadedFilesUrls
        } : {};
      }
      const res = await sendDataToFormViaProxy({
        formId: LOGGED_IN_SUPPORT_FORM,
        data: {
          firstname: values.firstname || values.username,
          lastname: values.lastname || "",
          username: values.username,
          email: values.username,
          organisation: values.organisation || "",
          message: values.message,
          ...fileObj,
          xdi_support_platform: "XDI Hub",
          xdi_support_issue_type: values.xdi_support_issue_type || ""
        }
      });
      if (res?.status == "error") {
        throw new Error();
      }
      form.resetFields();
      notify.success({
        message: t("form.successMessage.title"),
        description: t("form.successMessage.description"),
        duration: 0
      });
      onClose();
      setIsSubmitting(false);
    } catch (err: any) {
      console.log(err);
      setErrorMsg("message" in err ? err.message : t("form.errorMessage"));
      setIsSubmitting(false);
    }
  };
  return <Modal width={600 + 48} footer={null} open={isOpen} onCancel={() => {
    form.resetFields();
    onClose();
  }} data-sentry-element="Modal" data-sentry-component="XdiSupportForm" data-sentry-source-file="XdiSupportForm.tsx">
      <div style={{
      height: "auto",
      width: 600
    }}>
        {notificationContext}
        <Title level={1} className={styles.title} data-sentry-element="Title" data-sentry-source-file="XdiSupportForm.tsx">
          {t("title")}
        </Title>
        <br />
        <Form form={form} layout="vertical" requiredMark={false} style={{
        height: "auto",
        width: 600
      }} className={styles.form} onFinish={values => handleSubmit({
        ...values,
        xdi_support_issue_type: values?.xdi_support_issue_type?.value || ""
      })} initialValues={{
        username: currentUser?.email,
        organisation: currentUser?.org_name
      }} data-sentry-element="Form" data-sentry-source-file="XdiSupportForm.tsx">
          <div className="grid grid-cols-6 gap-x-4">
            <Form.Item className="col-span-3" name="firstname" label={t("form.firstname")} data-sentry-element="unknown" data-sentry-source-file="XdiSupportForm.tsx">
              <Input data-sentry-element="Input" data-sentry-source-file="XdiSupportForm.tsx" />
            </Form.Item>
            <Form.Item className="col-span-3" name="lastname" label={t("form.lastname")} data-sentry-element="unknown" data-sentry-source-file="XdiSupportForm.tsx">
              <Input data-sentry-element="Input" data-sentry-source-file="XdiSupportForm.tsx" />
            </Form.Item>
          </div>
          <Form.Item name="username" label={<div>
                <label>{t("form.username")}</label>
                <div className="text-xs text-gray-400">
                  This should be the email address associated to your XDI account
                </div>
              </div>} rules={[{
          required: true,
          message: "Please enter your email or username"
        }]} data-sentry-element="unknown" data-sentry-source-file="XdiSupportForm.tsx">
            <Input disabled={!!currentUser?.email} data-sentry-element="Input" data-sentry-source-file="XdiSupportForm.tsx" />
          </Form.Item>

          <Form.Item rules={[{
          required: true,
          message: "Please enter your organisation"
        }]} name="organisation" label={<div>
                <label>{t("form.organisation")}</label>
                <div className="text-xs text-gray-400">
                  This should be the Organisation under which your Hub account is registered
                </div>
              </div>} data-sentry-element="unknown" data-sentry-source-file="XdiSupportForm.tsx">
            <Input disabled={!!currentUser?.org_name} data-sentry-element="Input" data-sentry-source-file="XdiSupportForm.tsx" />
          </Form.Item>
          <Form.Item name="xdi_support_issue_type" label={t("form.xdi_support_issue_type")} rules={[{
          required: true,
          message: "Please select Support request category"
        }]} data-sentry-element="unknown" data-sentry-source-file="XdiSupportForm.tsx">
            <Select onSelect={value => {
            console.log(value);
            form.setFieldsValue({
              xdi_support_issue_type: {
                label: value,
                value
              }
            });
          }} options={issueTypeOptions.map(option => ({
            value: option.value,
            label: <div>
                    <div>{option.value}</div>
                    <div className="text-xs text-gray-400">{option.description}</div>
                  </div>
          }))} data-sentry-element="Select" data-sentry-source-file="XdiSupportForm.tsx" />
          </Form.Item>
          <Form.Item rules={[{
          required: true,
          message: "Please enter a message"
        }]} name="message" label={<div>
                <label>{t("form.message")}</label>
                <div className="text-xs text-gray-400">
                  Please tell us about your support enquiry so we can best assist
                </div>
              </div>} data-sentry-element="unknown" data-sentry-source-file="XdiSupportForm.tsx">
            <TextArea rows={3} data-sentry-element="TextArea" data-sentry-source-file="XdiSupportForm.tsx" />
          </Form.Item>
          <Form.Item name="file_upload" label="File Upload" valuePropName="fileList" getValueFromEvent={(e: any) => e.fileList} rules={[{
          validator: (_: any, fileList: UploadFile[]) => {
            if (!fileList) {
              return Promise.resolve();
            }
            const invalidFiles = fileList.filter(file => file.size! / 1024 / 1024 > MAX_FILE_SIZE_MB);
            if (invalidFiles.length > 0) {
              return Promise.reject(new Error(`One or more files exceed the ${MAX_FILE_SIZE_MB}MB size limit.`));
            }
            return Promise.resolve();
          }
        }]} data-sentry-element="unknown" data-sentry-source-file="XdiSupportForm.tsx">
            <Upload multiple beforeUpload={() => false} data-sentry-element="Upload" data-sentry-source-file="XdiSupportForm.tsx">
              <Button icon={<UploadOutlined />} data-sentry-element="Button" data-sentry-source-file="XdiSupportForm.tsx">Upload</Button>
            </Upload>
          </Form.Item>
          {errorMsg && <Alert type="error" message={errorMsg} className={styles.alert} />}
          <Form.Item data-sentry-element="unknown" data-sentry-source-file="XdiSupportForm.tsx">
            <Button type="primary" block htmlType="submit" loading={isSubmitting} data-sentry-element="Button" data-sentry-source-file="XdiSupportForm.tsx">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>;
};
import { ContextualInfoButtonWithText } from "@/components/buttons/ContextualInfoButton";
import { HubFormItem } from "@/components/inputs/HubFormItem";
import { SelectWithDescriptions, SelectWithDescriptionsProps } from "@/components/inputs/SelectWithDescriptions";
import { RiskBandsBuildYearSelector } from "@/components/layouts/RiskBandsBuildYearSelector";
import { RiskBandsHeightAboveGroundSelector } from "@/components/layouts/RiskBandsHeightAboveGroundSelector";
import { getArchetypes } from "@/features/branding";
import { RiskBandsConfig } from "@/features/risk_bands";
import { useGetOrgQuery } from "@/store/services/supabase";
import { RiskBandsAdvancedSettingsValues, RiskBandsArchetype } from "@/tools/risk-bands/types";
import { Alert, Col, Form, Row } from "antd";
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
interface RiskBandsAdvancedSettingsProps {
  mode?: "single" | "multiple";
  setRefs?: (refs: RiskBandsAdvancedSettingsRefs) => void;
  values: RiskBandsAdvancedSettingsValues;
  setValues: Dispatch<SetStateAction<RiskBandsAdvancedSettingsValues>>;
}
export interface RiskBandsAdvancedSettingsRefs {
  archetype: MutableRefObject<null>;
  buildYear: MutableRefObject<null>;
  heightAboveGround: MutableRefObject<null>;
}
export const RiskBandsAdvancedSettings = ({
  mode = "single",
  values,
  setRefs,
  setValues
}: RiskBandsAdvancedSettingsProps) => {
  const {
    t
  } = useTranslation("tools", {
    keyPrefix: "settings"
  });
  const archetypeRef = useRef(null);
  const buildYearRef = useRef(null);
  const heightAboveGroundRef = useRef(null);
  const {
    data: org
  } = useGetOrgQuery();
  const archetypes = getArchetypes().filter(({
    id
  }) => id !== "automobile-example" || org?.slug?.startsWith("bnm") || org?.slug?.startsWith("tcrg")).map(archetype => ({
    ...archetype,
    id: archetype.value
  }));
  const defaultArchetype = archetypes[0];
  const [archetypeWarning, setArchetypeWarning] = useState(defaultArchetype.warning);
  useEffect(() => {
    const {
      warning
    } = archetypes.find(({
      id
    }) => id === values.archetype)!;
    setArchetypeWarning(warning);
  }, [values.archetype]);
  const handleBuildYearChange = (source: string, amount: number) => {
    setValues(v => ({
      ...v,
      buildYear: {
        source,
        amount
      }
    }));
  };
  const handleHeightAboveGroundChange = (source: string, amount: number) => {
    setValues(v => ({
      ...v,
      heightAboveGround: {
        source,
        amount
      }
    }));
  };
  useEffect(() => {
    if (!setRefs) {
      return;
    }
    setRefs({
      archetype: archetypeRef,
      buildYear: buildYearRef,
      heightAboveGround: heightAboveGroundRef
    });
  }, [archetypeRef, buildYearRef, heightAboveGroundRef]);
  return <Form layout="vertical" data-sentry-element="Form" data-sentry-component="RiskBandsAdvancedSettings" data-sentry-source-file="RiskBandsAdvancedSettings.tsx">
      <Row gutter={[16, 0]} data-sentry-element="Row" data-sentry-source-file="RiskBandsAdvancedSettings.tsx">
        {mode === "multiple" && <Col span={24} style={{
        marginBottom: "1rem"
      }}>
            <Alert type="info" message="Quick Batch applies the same settings to all supplied addresses." />
          </Col>}
        <Col ref={archetypeRef} span={24} data-sentry-element="Col" data-sentry-source-file="RiskBandsAdvancedSettings.tsx">
          <HubFormItem label={<ContextualInfoButtonWithText id="archetype">{t("archetype.title")}</ContextualInfoButtonWithText>} data-sentry-element="HubFormItem" data-sentry-source-file="RiskBandsAdvancedSettings.tsx">
            <SelectWithDescriptions aria-label="Asset Type (Archetype)" onChange={(props: RiskBandsArchetype) => {
            setValues({
              ...values,
              archetype: props as RiskBandsArchetype
            });
          }} options={buildArchetypeSelectProps(archetypes)} value={values?.archetype} data-sentry-element="SelectWithDescriptions" data-sentry-source-file="RiskBandsAdvancedSettings.tsx" />
            {archetypeWarning && <Alert type="warning" message={archetypeWarning} showIcon style={{
            marginTop: ".5rem"
          }} />}
          </HubFormItem>
        </Col>

        <Col ref={buildYearRef} span={24} data-sentry-element="Col" data-sentry-source-file="RiskBandsAdvancedSettings.tsx">
          <HubFormItem label={<ContextualInfoButtonWithText id="buildYear">{t("buildYear.title")}</ContextualInfoButtonWithText>} data-sentry-element="HubFormItem" data-sentry-source-file="RiskBandsAdvancedSettings.tsx">
            {/* <InputNumber
              aria-label="Build Year"
              onChange={(value) => setValues({ ...values, buildYear: value || defaultBuildYear })}
              min={inputConstraints.buildYear.min}
              max={inputConstraints.buildYear.max}
              precision={0}
              value={values?.buildYear}
              style={{ width: "100%" }}
             /> */}
            <RiskBandsBuildYearSelector archetype={values.archetype || ""} buildYear={values.buildYear} onChange={handleBuildYearChange} data-sentry-element="RiskBandsBuildYearSelector" data-sentry-source-file="RiskBandsAdvancedSettings.tsx" />
          </HubFormItem>
        </Col>
        <Col ref={heightAboveGroundRef} span={24} data-sentry-element="Col" data-sentry-source-file="RiskBandsAdvancedSettings.tsx">
          <HubFormItem label={<ContextualInfoButtonWithText id="heightAboveGround">
                {t("heightAboveGround.title")}
              </ContextualInfoButtonWithText>} data-sentry-element="HubFormItem" data-sentry-source-file="RiskBandsAdvancedSettings.tsx">
            <RiskBandsHeightAboveGroundSelector archetype={values.archetype || ""} heightAboveGround={values.heightAboveGround} onChange={handleHeightAboveGroundChange} data-sentry-element="RiskBandsHeightAboveGroundSelector" data-sentry-source-file="RiskBandsAdvancedSettings.tsx" />
          </HubFormItem>
        </Col>
      </Row>
    </Form>;
};
const buildArchetypeSelectProps = (archetypes: RiskBandsConfig["archetypes"]) => {
  const categorySet = new Set();
  archetypes.forEach(({
    category
  }) => categorySet.add(category));
  if (categorySet.size > 1) {
    return archetypes.reduce((list, archetype) => {
      let isListChanged = false;
      const updatedList = list.map(item => {
        // If the OptionGroup already exist, just append archetype to it.
        if (item.id === archetype.category && !isListChanged) {
          isListChanged = true;
          return {
            ...item,
            options: [...(item.options || []), archetype]
          };
        }
        return item;
      });

      // If OptionGroup for archetype does not yet exist, create it.
      return isListChanged ? updatedList : [...list, {
        category: archetype.category,
        id: archetype.category,
        value: archetype.category,
        name: {
          residential: "Residential",
          commercial: "Commercial",
          industrial: "Industrial"
        }[archetype.category],
        options: [archetype]
      }];
    }, [] as SelectWithDescriptionsProps["options"]);
  }
  return archetypes;
};
import { getArchetypes, getConfig } from "@/features/branding";
import { defaultBuildYear } from "@/features/risk_bands";
import { RiskBandsAdvancedSettingsHAG } from "@/tools/risk-bands/types";
import { InputNumber, Select } from "antd";
import { useEffect } from "react";
interface RiskBandsBuildYearSelectorProps {
  archetype: string;
  buildYear: RiskBandsAdvancedSettingsHAG;
  onChange: (value: string, amount: number) => void;
}
export const RiskBandsBuildYearSelector = ({
  archetype,
  buildYear,
  onChange
}: RiskBandsBuildYearSelectorProps) => {
  const {
    inputConstraints
  } = getConfig();
  const archetypes = getArchetypes();
  const defaultValue = archetypes.find(({
    value
  }) => value === archetype)?.defaultBuildYear || defaultBuildYear;
  const options = [{
    value: "archetype",
    label: `Default for Asset Type (${defaultValue})`,
    amount: defaultValue
  }, {
    value: "custom",
    label: "Custom value"
  }];
  useEffect(() => {
    const option = options.find(({
      value
    }) => value === buildYear.source)!;
    if (option && "amount" in option && option.amount !== undefined) {
      onChange(option.value, option.amount);
    }
  }, [archetype]);
  const handleBuildYearChange = (value: string) => {
    if (value === "custom") {
      onChange("custom", defaultValue);
    }
    const option = options.find(opt => opt.value === value)!;
    if ("amount" in option && option.amount !== undefined) {
      onChange(option.value, option.amount);
    }
  };
  return <div style={{
    display: "flex"
  }} data-sentry-component="RiskBandsBuildYearSelector" data-sentry-source-file="RiskBandsBuildYearSelector.tsx">
      <Select aria-label="Build Year Mode" value={buildYear.source} onChange={handleBuildYearChange} placeholder="Select a build year" options={options} data-sentry-element="Select" data-sentry-source-file="RiskBandsBuildYearSelector.tsx" />

      {buildYear.source === "custom" && <InputNumber aria-label="Custom Build Year" onChange={value => onChange("custom", value || 0)} min={inputConstraints.buildYear.min} max={inputConstraints.buildYear.max} value={buildYear.amount} style={{
      marginLeft: ".5rem"
    }} />}
    </div>;
};
import { EmbedPage } from "@/features/supabase";
import type { Props } from "../types";
import { Embed } from "./index";
export const EmbedPageContent = (data: EmbedPage) => {
  let embedData: Props | undefined;
  switch (data.embed_type) {
    case "airtable":
      embedData = {
        type: "airtable",
        slug: data.embed_slug
      };
      break;
    case "metabase-dashboard":
      embedData = {
        type: "metabaseDashboard",
        id: data.embed_slug
      };
      break;
    case "scribe":
      embedData = {
        type: "scribe",
        slug: data.embed_slug
      };
      break;
    case "youtube":
      embedData = {
        type: "youtube",
        id: data.embed_slug
      };
      break;
  }
  return embedData ? <Embed data={embedData} data-sentry-element="Embed" data-sentry-component="EmbedPageContent" data-sentry-source-file="EmbedPageContent.tsx" /> : <>Could not load page. Please contact support.</>;
};
import { useGetToolUsageQuery } from "@/store/services/supabase";
import PortfolioExportFairUseWarning from "@/tools/aggregate/portfolio-export/components/PortfolioExportFairUseWarning";
import { PortfolioExportJobInformation } from "@/tools/aggregate/portfolio-export/components/PortfolioExportJobInformation";
import { PortfolioExportJobSchema } from "@/tools/aggregate/portfolio-export/types";
import { SearchOutlined } from "@ant-design/icons";
import { Alert, Divider, Input, List, Skeleton } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
interface PortfolioExportJobTableBodyProps {
  schemas: PortfolioExportJobSchema[];
  loading: boolean;
  decrypting: boolean;
  updateSchema: (schema: PortfolioExportJobSchema) => void;
  onDelete: (id: string) => void;
  onError: (err: string | string[], portfolioId: string) => void;
  onEdit: (props: {
    schema: PortfolioExportJobSchema;
    name: string;
    isPrivate: boolean;
    groupId: string;
  }) => Promise<void>;
}
export const PortfolioExportJobTableBody = ({
  schemas,
  loading,
  decrypting,
  updateSchema,
  onDelete,
  onError,
  onEdit
}: PortfolioExportJobTableBodyProps) => {
  const {
    data
  } = useGetToolUsageQuery({});
  const {
    t
  } = useTranslation("tools", {
    keyPrefix: "aggregate.portfolioExport"
  });
  const [search, setSearch] = useState("");
  const filteredSchema = search ? schemas.filter(({
    name
  }) => name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) : schemas;
  const isProcessing = schemas.filter(({
    status
  }) => ["processing", "pending", "provisioning", "running", "uploading"].includes(status)).length > 0;
  return <>
      {data && <PortfolioExportFairUseWarning data={data} />}

      <div className="mb-4 mt-4 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <Input prefix={<SearchOutlined style={{
          height: 16
        }} />} placeholder="Search..." value={search} onChange={e => setSearch(e.target.value)} className="max-w-[400px]" allowClear data-sentry-element="Input" data-sentry-source-file="PortfolioExportJobTableBody.tsx" />
          {search && <div className="text-gray-500">{filteredSchema.length} items</div>}
        </div>
      </div>

      {isProcessing && <Alert message={<div className="text-sm font-medium text-sky-700">{t("processingAlert.title")}</div>} description={<div className="text-xs text-sky-700">{t("processingAlert.blurb")}</div>} rootClassName="border-none p-4 mb-4" />}

      <div className="relative overflow-x-scroll">
        <List dataSource={filteredSchema} itemLayout="vertical" pagination={false} rowKey="id" className={`mb-4 min-h-36 overflow-hidden rounded-lg border border-gray-200`} size="large" loading={{
        indicator: <>
                <CustomSkeleton />
                <CustomSkeleton />
              </>,
        // Show spinner only during network request
        // Recognises difference between no data and the first decrypting step
        spinning: loading || decrypting && !filteredSchema.length,
        size: "large"
      }} renderItem={schema => {
        return <List.Item
        // TODO: Move JobActions into this property.
        // actions={[<div key="1">Wow</div>]}
        className="b-0 !border-gray-200 px-4 odd:bg-gray-50">
                <PortfolioExportJobInformation onDelete={() => onDelete(schema.id)} onEdit={onEdit} schema={schema} updateSchema={updateSchema} onError={onError} />
              </List.Item>;
      }} data-sentry-element="List" data-sentry-source-file="PortfolioExportJobTableBody.tsx" />
        {/* Show skeleton only during decryption */}
        {!loading && decrypting && <>
            <CustomSkeleton />
            <CustomSkeleton />
            <CustomSkeleton />
            <CustomSkeleton />
          </>}
      </div>
    </>;
};
const CustomSkeleton = () => <>
    <div className="space-y-2 px-6 py-4">
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-col space-y-2">
          <Skeleton.Input active size="small" className="!h-4 !w-[300px]" data-sentry-element="unknown" data-sentry-source-file="PortfolioExportJobTableBody.tsx" />
          <Skeleton.Input active size="small" className="!h-4 !w-[500px]" data-sentry-element="unknown" data-sentry-source-file="PortfolioExportJobTableBody.tsx" />
        </div>
        <div className="hidden space-x-2 lg:flex">
          <Skeleton.Button active size="small" className="!w-[100px]" data-sentry-element="unknown" data-sentry-source-file="PortfolioExportJobTableBody.tsx" />
          <Skeleton.Button active size="small" className="!w-[100px]" data-sentry-element="unknown" data-sentry-source-file="PortfolioExportJobTableBody.tsx" />
          <Skeleton.Button active size="small" className="!w-[30px] !min-w-0" data-sentry-element="unknown" data-sentry-source-file="PortfolioExportJobTableBody.tsx" />
        </div>
      </div>
    </div>
    <Divider className="m-0" data-sentry-element="Divider" data-sentry-source-file="PortfolioExportJobTableBody.tsx" />
  </>;
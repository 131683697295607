import { encryptSecret, importKey } from "@/features/cryptography";
import supabase from "@/features/supabase";
import { useGetPendingUsersForOrgKeyExchangeQuery, useSetOrgSecretsForPendingUsersMutation } from "@/store/services/supabase";
import { arrayBufferToString } from "@/utils/crypto";
import { useKeys } from "@/utils/hooks/useKeys";
import { CloseOutlined } from "@ant-design/icons";
import { useInterval } from "@mantine/hooks";
import { RealtimeChannel } from "@supabase/supabase-js";
import { Button, message, Modal } from "antd";
import { throttle } from "lodash/fp";
import { PropsWithChildren, useEffect, useState } from "react";
export const OrgKeyExchange = ({
  children
}: PropsWithChildren) => {
  const [keys] = useKeys();
  const {
    data: members,
    refetch
  } = useGetPendingUsersForOrgKeyExchangeQuery();
  const [setOrgKeys] = useSetOrgSecretsForPendingUsersMutation();
  const [channel, setChannel] = useState<RealtimeChannel | undefined>(undefined);
  const [messageApi, messageContext] = message.useMessage();
  const [modal, modalContext] = Modal.useModal();
  const handleModalOpen = (members: any[]) => {
    modal.info({
      width: 500,
      content: <div>
          Encryption keys successfully exchanged with the following member{members.length > 1 ? "s" : ""} of your
          organisation. No further action required.
          <ul className="list-inside list-disc">
            {members.map(m => <li key={m.user_id}>{m.email}</li>)}
          </ul>
        </div>
    });
  };
  const handleOrgKeyExchange = throttle(100, async () => {
    console.log("handleOrgKeyExchange");
    const secret = keys.orgSecret;
    if (!secret) return;
    if (!members || !members.length) return;
    if (members) {
      const withEncryptedSecrets = await Promise.all(members.map(async user => {
        let org_secret_enc = "";
        try {
          const encryptedSecret = await encryptSecret({
            publicKey: await importKey(user.public_key, ["encrypt"]),
            secret
          });
          org_secret_enc = arrayBufferToString(encryptedSecret);
        } catch {
          console.error("Error adding Org Secret to: ", user.email);
        }
        return {
          user_id: user.id,
          group_id: user.group_id,
          email: user.email,
          org_secret_enc
        };
      })).then(members => members.filter(member => member.org_secret_enc !== ""));
      const res = await setOrgKeys(withEncryptedSecrets);
      if ("error" in res) {
        console.error(res);
        return;
      }
      if (!withEncryptedSecrets.length) {
        return;
      }
      const MESSAGE_ID = `MSG-${new Date().getTime()}`;
      messageApi.success({
        key: MESSAGE_ID,
        duration: 30,
        content: <div className="flex items-center">
            <span className="pr-6">
              Encryption keys exchanged with {withEncryptedSecrets.length} member
              {withEncryptedSecrets.length > 1 ? "s" : ""}.
            </span>
            <Button type="text" size="small" onClick={() => {
            handleModalOpen(withEncryptedSecrets);
            messageApi.destroy(MESSAGE_ID);
          }}>
              Details
            </Button>
            <Button type="text" size="small" icon={<CloseOutlined />} onClick={() => messageApi.destroy(MESSAGE_ID)} />
          </div>
      });
    }
  });
  useEffect(() => {
    handleOrgKeyExchange();
  }, [members, keys]);

  // Check for channel existing every three seconds.
  const interval = useInterval(() => {
    if (channel) {
      return;
    }
    console.log("OKE: RESUBSCRIBING");
    subscribe();
  }, 3000);

  /** Subscribes to the Supabase channel, if no subscription exists. */
  const subscribe = () => {
    if (!interval.active) {
      console.log("ACTIVATE INTERVAL");
      setTimeout(interval.start, 1000);
    }
    if (channel) {
      console.error("Can't subscribe when channel is already connected.");
      return;
    }
    console.log("OKE: SUBSCRIBING");
    const newChannel = supabase.channel("user_update").on("postgres_changes", {
      event: "UPDATE",
      schema: "public",
      table: "users"
    }, refetch).subscribe(async status => {
      if (!channel) {
        return;
      }
      console.log("OKE: EVENT - " + status);
      switch (status) {
        case "CHANNEL_ERROR":
        case "TIMED_OUT":
          await supabase.removeChannel(channel);
          setChannel(undefined);
          break;
        case "CLOSED":
          setChannel(undefined);
          break;
      }
    });
    setChannel(newChannel);
  };
  useEffect(() => {
    subscribe();
    return () => {
      console.log("OKE: DISMOUNTING");
      interval.stop();
      if (channel) {
        supabase.removeChannel(channel);
        setChannel(undefined);
      }
    };
  }, []);
  return <>
      {messageContext}
      {modalContext}

      {children}
    </>;
};
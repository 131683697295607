import { getArchetypes } from "@/features/branding";
import { isPointWithinSystemBounds } from "@/features/geographic_data";
import {
  enumErrorMap,
  keys,
  PortfolioExportRawSchema,
  rawSchemaBase,
  rawSchemaLocation,
} from "@/tools/aggregate/portfolio-export/schema";
import { parse } from "@climaterisk/papaparse";
import { RcFile } from "antd/es/upload";
import { z } from "zod";

/** Result from the validation of a .csv file intended for PortfolioExport. */
interface ValidationResult {
  /** Outcome of the validation process. */
  status: "success" | "error";
  /**
   * Any errors (in case of "error" status) or warnings (in case of "success"
   * status) generated during validation.
   */
  messages: string[];
}

/**
 * Ensures that a chosen CSV file is valid for Portfolio Export upload.
 * @param file - Object generated when a file is selected for upload.
 */
export const validateCsvFile = async (
  file: RcFile,
  portfolioCount: { min: number; max: number }
): Promise<ValidationResult> => {
  const archetypes = Object.freeze(getArchetypes().map(({ name }) => name)) as readonly [string, ...string[]];

  /** Count of all blank IDs. */
  let blankIdCount = 0;
  /** Count of all coordinates which are outside of system bounds. */
  let outOfBoundsCoordinatesCount = 0;
  /** Count of all lines that have been processed successfully. */
  let processedLineCount = 0;
  /** Using a set ensures that no IDs are duplicated. */
  const uniqueIds = new Set<string>();

  return new Promise((resolve) => {
    parse<PortfolioExportRawSchema>(file, {
      dynamicTyping: true,
      header: true,
      skipEmptyLines: true,
      step: ({ data }, parser) => {
        // TODO: First, check to ensure all headers are correct.

        // Wrap schema to use specific archetypes available on this instance.
        const rawSchema = rawSchemaBase
          .extend({
            [keys.archetype]: z.enum(archetypes, { errorMap: enumErrorMap }).nullish().optional(),
          })
          .and(rawSchemaLocation);

        // Validate data
        const result = rawSchema.safeParse(data);

        const id = data[keys.id];
        if (result.success) {
          processedLineCount++;
          if (id) {
            uniqueIds.add(String(id));
          } else {
            blankIdCount++;
          }

          // Handle out of bounds coordinates.
          if (keys.latitude in result.data && keys.longitude in result.data) {
            const lat = result.data[keys.latitude];
            const lng = result.data[keys.longitude];

            if (!isNaN(lat) && !isNaN(lng) && !isPointWithinSystemBounds({ lat: +lat, lng: +lng })) {
              outOfBoundsCoordinatesCount++;
            }
          }
        } else {
          console.log("FAILED VALIDATION:", id);
          let identity = `Row ${processedLineCount + 2}`;
          if (id) identity += ` (ID: ${id})`;

          resolve({
            status: "error",
            messages: result.error.issues.map(({ message }) => `${identity}: ${message}`),
          });
          parser.abort();
        }
      },
      complete: () => {
        if (outOfBoundsCoordinatesCount > 0) {
          resolve({
            status: "error",
            messages: ["Assets found of out system bounds."],
          });
        }
        if (portfolioCount.min && processedLineCount < portfolioCount.min) {
          resolve({
            status: "error",
            messages: [`Portfolio size must be at least ${portfolioCount.min}.`],
          });
        }
        if (portfolioCount.max && processedLineCount > portfolioCount.max) {
          resolve({
            status: "error",
            messages: [`Portfolio size cannot exceed ${portfolioCount.max}.`],
          });
        }
        if (uniqueIds.size + blankIdCount !== processedLineCount) {
          resolve({
            status: "error",
            messages: ["Some IDs were duplicated!"],
          });
        } else {
          resolve({
            status: "success",
            messages: ["Assets validated"],
          });
        }
      },
      error: (error) => {
        resolve({
          status: "error",
          messages: [error.message],
        });
      },
    });
  });
};

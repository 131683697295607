import { Embed } from "./components";
import { EmbedPageContent } from "./components/EmbedPageContent";
import { Modal as EmbedModal, ModalProps as EmbedModalProps } from "./components/Modal";
import type {
  AirtableProps as AirtableEmbedProps,
  HubspotFormProps as HubspotFormEmbedProps,
  MetabaseDashboardProps as MetabaseDashboardEmbedProps,
  Props as EmbedProps,
  ScribeProps as ScribeEmbedProps,
  YouTubeProps as YouTubeEmbedProps,
} from "./types";
import { propsSchema as embedPropsSchema } from "./types";

export { Embed, EmbedModal, EmbedPageContent, embedPropsSchema };
export type {
  EmbedModalProps,
  EmbedProps,
  AirtableEmbedProps,
  HubspotFormEmbedProps,
  MetabaseDashboardEmbedProps,
  ScribeEmbedProps,
  YouTubeEmbedProps,
};

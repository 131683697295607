import { createBrowserClient } from "@supabase/ssr";
import { SupabaseClient } from "@supabase/supabase-js";
import type { Database, Json } from "./types";

class Supabase {
  private static instance: Supabase | null = null;
  private supabase: SupabaseClient<Database>;

  constructor() {
    this.supabase = createBrowserClient<Database>(
      process.env.NEXT_PUBLIC_SUPABASE_URL!,
      process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!
    );
  }

  static getInstance() {
    if (!Supabase.instance) {
      Supabase.instance = new Supabase();
    }

    return Supabase.instance;
  }

  public getSupabase() {
    return this.supabase;
  }
}

const supabaseInstance = Supabase.getInstance();

export type EmbedPage = Database["public"]["Tables"]["embed_pages"]["Row"];

export default supabaseInstance.getSupabase();
export type { Database as SupabaseDatabase, Json as SupabaseJson };

import { getArchetypes } from "@/features/branding";
import { facadesApi } from "@/store/services/facades";
import { FacadesBatchPostResponse } from "@/store/services/facades/types";
import {
  RawSimpleCommercialResponse,
  RawSimpleResidentialResponse,
  SimpleCommercialRequest,
  SimpleCommercialResponse,
  SimpleResidentialRequest,
  SimpleResidentialResponse,
} from "@/store/services/facades/types/simple";

const HEADERS = {
  Authorization: `Basic ${process.env.NEXT_PUBLIC_FACADES_ABCDE_API_KEY}`,
};

export const simpleFacadesApi = facadesApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Retrieve Simple Structural commercial risk bands.
     */
    getSimpleCommercialRiskBands: build.query<SimpleCommercialResponse, SimpleCommercialRequest>({
      query: (body) => {
        return {
          method: "POST",
          url: "structural/simple/commercial",
          headers: HEADERS,
          body,
        };
      },
      transformErrorResponse: (error) => {
        const errorData = error.data as {
          title: string;
          status: number;
          detail: string;
          type: string;
        };

        const { detail, title } = errorData;

        return {
          message: title && detail ? `${title}. ${detail}.` : "Something unexpected happened. Please try again.",
          status: errorData.status,
        };
      },
      transformResponse: (response: RawSimpleCommercialResponse): SimpleCommercialResponse => {
        return {
          mode: "simple_structural",
          submode: "commercial",
          ...response,
        };
      },
    }),

    /**
     * Retrieve Simple Structural residential risk bands.
     */
    getSimpleResidentialRiskBands: build.query<SimpleResidentialResponse, SimpleResidentialRequest>({
      query: (body) => {
        return {
          method: "POST",
          url: "structural/simple/residential",
          headers: HEADERS,
          body,
        };
      },
      transformErrorResponse: (error) => {
        const errorData = error.data as {
          title: string;
          status: number;
          detail: string;
          type: string;
        };

        const { detail, title } = errorData;

        return {
          message: title && detail ? `${title}. ${detail}.` : "Something unexpected happened. Please try again.",
          status: errorData.status,
        };
      },
      transformResponse: (response: RawSimpleResidentialResponse): SimpleResidentialResponse => {
        return {
          mode: "simple_structural",
          submode: "residential",
          ...response,
        };
      },
    }),

    /**
     * Send Simple Structural batch items, determining endpoint by archetypes.
     */
    postSimpleStructuralBatch: build.query<FacadesBatchPostResponse, string[]>({
      queryFn: async (items, api) => {
        try {
          const archetypes = getArchetypes();
          const { commercial, residential, industrial } = items.reduce(
            (data, item) => {
              const { archetype } = JSON.parse(item);
              const { category } = archetypes.find(({ value }) => value === archetype)!;
              return {
                ...data,
                [category]: [...data[category], item],
              };
            },
            {
              commercial: [] as string[],
              residential: [] as string[],
              industrial: [] as string[],
            }
          );

          const response: FacadesBatchPostResponse = {
            errors: 0,
            successes: 0,
            stamps: [],
            error_summary: [],
          };

          if (commercial.length + industrial.length) {
            const action = simpleFacadesApi.endpoints.postSimpleStructuralCommercialBatch.initiate([
              ...commercial,
              ...industrial,
            ]);
            const { data } = await api.dispatch(action);

            if (data) {
              response.errors += data.errors;
              response.successes += data.successes;
              response.stamps?.push(...(data.stamps || []));
              response.error_summary?.push(...(data.error_summary || []));
            }
          }

          if (residential.length) {
            const action = simpleFacadesApi.endpoints.postSimpleStructuralResidentialBatch.initiate(residential);
            const { data } = await api.dispatch(action);

            if (data) {
              response.errors += data.errors;
              response.successes += data.successes;
              response.stamps?.push(...(data.stamps || []));
              response.error_summary?.push(...(data.error_summary || []));
            }
          }

          return {
            data: response,
          };
        } catch (error) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: `${error}`,
            },
          };
        }
      },
    }),

    /**
     * Send Simple Structural residential batch items.
     * NOTE: You mostly want to use postSimpleStructuralBatch instead, which calls this.
     */
    postSimpleStructuralResidentialBatch: build.query<FacadesBatchPostResponse, string[]>({
      query: (body) => {
        return {
          method: "POST",
          url: "structural/simple/residential/batch",
          headers: {
            ...HEADERS,
            "Content-Type": "application/x-ndjson",
          },
          body: body.join("\n"),
        };
      },
    }),

    /**
     * Send Simple Structural commercial batch items.
     * NOTE: You mostly want to use postSimpleStructuralBatch instead, which calls this.
     */
    postSimpleStructuralCommercialBatch: build.query<FacadesBatchPostResponse, string[]>({
      query: (body) => {
        return {
          method: "POST",
          url: "structural/simple/commercial/batch",
          headers: {
            ...HEADERS,
            "Content-Type": "application/x-ndjson",
          },
          body: body.join("\n"),
        };
      },
    }),
  }),
});

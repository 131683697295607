import { decryptData } from "@/features/cryptography";
import { useLazyGetErroneousPortfolioAssetsQuery } from "@/store/services/supabase";
import { PortfolioExportJobSchema } from "@/tools/aggregate/portfolio-export/types";
import { KeyPairAndSalt } from "@/types/cryptography";
import { hexToArrayBuffer } from "@/utils/crypto";
import { Modal, ModalProps, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
export interface PortfolioExportJobErrorModalProps extends ModalProps {
  schema: PortfolioExportJobSchema;
  orgKey: KeyPairAndSalt;
}
type ErrorAssetType = {
  key: string;
  id: string;
  location: string;
  reason: string;
};

/**
 * Shows the information for an error in a Portfolio Export job.
 */
export const PortfolioExportJobErrorModal = ({
  schema,
  ...props
}: PortfolioExportJobErrorModalProps) => {
  const ERRORS_PER_PAGE = 100;
  const [assets, setAssets] = useState<ErrorAssetType[]>([]);
  const [page, setPage] = useState(1);
  const [errorMsg, setErrorMsg] = useState("");
  const [getErroneousPortfolioAssetsQuery, {
    data,
    error
  }] = useLazyGetErroneousPortfolioAssetsQuery();
  const columns: ColumnsType<ErrorAssetType> = [{
    title: "",
    key: "index",
    render: (text, record, index) => (page - 1) * ERRORS_PER_PAGE + index + 1
  }, {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 200
  }, {
    title: "Location",
    dataIndex: "location",
    key: "location",
    width: 360
  }, {
    title: "Reason",
    dataIndex: "reason",
    key: "reason"
  }];
  useEffect(() => {
    if (props.open && schema.assets.errorCount) {
      getErroneousPortfolioAssetsQuery({
        portfolioId: schema.id,
        length: ERRORS_PER_PAGE,
        start: (page - 1) * ERRORS_PER_PAGE
      });
    }
  }, [props.open, schema.assets.errorCount]);
  useEffect(() => {
    setErrorMsg("");
    if (!props.open || !data || !props.orgKey || !props.orgKey.keyPair) {
      setAssets([]);
      return;
    }
    const promises = data.map(async item => {
      try {
        const data = JSON.parse(item.data ? await decryptData(schema.secret, hexToArrayBuffer(item.data)) : "{}");
        let errorReason = "";
        try {
          const errors = JSON.parse(item.errors ? await decryptData(schema.secret, hexToArrayBuffer(item.errors)) : "{}");
          errorReason = `${errors.title}. ${errors.detail}.`;
        } catch (error) {
          console.log("Error decrypting logged error:", error);
          errorReason = "Could not decode error information";
        }
        const location = "geocoding" in data ? data.geocoding.address : `(${data.latitude}, ${data.longitude})`;
        return {
          key: data.item_id,
          id: data.item_id,
          location,
          reason: errorReason
        };
      } catch (error) {
        console.error("Error decrypting asset containing error:", error);
        setErrorMsg("Your error log could not be decrypted");
        return Promise.reject();
      }
    });
    Promise.all(promises).then(data => setAssets(data));
  }, [data, props.open]);
  return <Modal footer={null} title={`${schema.name} (${schema.assets.errorCount} errors)`} width={1080} {...props} data-sentry-element="Modal" data-sentry-component="PortfolioExportJobErrorModal" data-sentry-source-file="PortfolioExportJobErrorModal.tsx">
      {error || errorMsg ? "Could not load errors" : <>
          <Table columns={columns} dataSource={assets} size="small" style={{
        paddingBlockStart: "1rem"
      }} scroll={{
        x: 1000
      }} pagination={{
        current: page,
        onChange: setPage,
        pageSize: ERRORS_PER_PAGE,
        total: schema.assets.errorCount,
        position: ["bottomCenter"],
        showSizeChanger: false
      }} />
        </>}
    </Modal>;
};
import { HubspotUploadFileProps } from "@/features/support";
import { Data, Field, HubspotFormSubmissionProps } from "@/types/hubspot";
import { convertDataToFields, DEFAULT_HUBSPOT_PORTAL_ID } from "@/utils/hubspot";
const HUBSPOT_FORM_SUBMISSION_ENDPOINT = "https://api.hsforms.com/submissions/v3/integration/submit";
export const HUBSPOT_FORM_UPLOADS_FOLDER_ID = "107458176846";

/**
 * Upload file to HubSpot.
 * @param folderId - ID of folder we're sending data to.
 * @param file - file we're sending to hubspot.
 * @param options - hubspot file options sending.
 */
export const sendUploadedFileToHubspotViaProxy = async ({
  folderId = HUBSPOT_FORM_UPLOADS_FOLDER_ID,
  file,
  options = {
    access: "PRIVATE",
    ttl: "P1D"
  }
}: HubspotUploadFileProps) => {
  try {
    if (!file || !("originFileObj" in file)) {
      throw new Error("No file selected");
    }
    const formData = new FormData();
    formData.append("folderId", folderId as string);
    formData.append("file", file.originFileObj as File);
    formData.append("options", JSON.stringify(options));
    const response = await fetch("/api/file-upload", {
      method: "POST",
      body: formData
    });
    const data = await response.json();
    if (data?.status == "error") {
      throw new Error(data?.error || "File could not be uploaded uploaded");
    }
    return data;
  } catch (err) {
    console.log("Failed to upload file to HubSpot:", err);
    return {
      error: {
        status: "error",
        error: `Something went wrong while attaching your files.  Please try again.`
      }
    };
  }
};

/**
 * Fetch signed file url of uploaded private file from HubSpot.
 * @param fileId - file we're fetching from hubspot.
 */
export const fetchFileUrlFromHubspotViaProxy = async (fileId: string) => {
  try {
    const response = await fetch(`/api/file-upload?fileId=${fileId}`, {
      method: "GET"
    });
    const data = await response.json();
    if (data?.status == "error") {
      throw new Error(data?.error || "File url could not be fetched");
    }
    console.log("data", data);
    return data;
  } catch (err) {
    console.error("Failed to fetch file url from HubSpot:", err);
    return {
      error: {
        status: "error",
        error: `Something went wrong while fetching your file urls.  Please try again.`
      }
    };
  }
};
export const convertArrayToFields = (key: string, arr: Array<Data>): Field[] => {
  return arr.map(data => ({
    name: key,
    value: data
  }));
};

/**
 * Make a submission to a HubSpot form.
 * @param formId - ID of form we're sending data to.
 * @param data - Structured data for sending.
 */
export const sendDataToFormViaProxy = async ({
  formId,
  data,
  portalId = DEFAULT_HUBSPOT_PORTAL_ID
}: HubspotFormSubmissionProps) => {
  let fieldData = {};
  if ("file_upload" in data) {
    const {
      file_upload,
      ...nonFilefields
    } = data;
    fieldData = convertDataToFields(nonFilefields).concat(convertArrayToFields("file_upload", file_upload));
  } else {
    fieldData = convertDataToFields(data);
  }
  const response = await fetch(`/api/hubspot-proxy?endpoint=${HUBSPOT_FORM_SUBMISSION_ENDPOINT}/${portalId}/${formId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      submittedAt: new Date().getTime(),
      fields: fieldData,
      context: {
        hutk: null
      }
    })
  });
  const responseData = await response.json();
  return responseData;
};
import { getConfig, getDocsConfig } from "@/features/branding";
import { store } from "@/store";
import { CmsPost, internalApi } from "@/store/services/internal";
import { PostCategoryId, PostIds } from "./types";

/**
 * Retrieves a post from given data.
 * @param category - Category of post
 * @param id - ID of post
 * @returns - Post or undefined
 */
export const getPost = async (category: PostCategoryId, id: string): Promise<CmsPost | undefined> => {
  const slug = getPostId(category, id);

  return slug ? getPostBySlug(slug) : undefined;
};

/**
 * Retrieves a post via ID.
 * @param id - Post ID.
 * @returns - Post or undefined.
 */
export const getPostBySlug = async (id: string): Promise<CmsPost | undefined> => {
  const getAllPostsAction = internalApi.endpoints.getCmsPost.initiate(id);
  const { status, data } = await store.dispatch(getAllPostsAction);

  return status === "rejected" || !data ? undefined : data;
};

/**
 * Finds the post ID for a given CMS post, if it exists.
 * @param category - Internal category (eg. "glossary").
 * @param id - Internal ID (eg. "rcps", "riskBands").
 * @returns - "Numerical" ID for HubSpot CMS Hub.
 */
export const getPostId = (category: PostCategoryId, id: string): string | undefined => {
  const { overrides } = getDocsConfig().contextualElementTutorials;

  // We either use the override or the default.
  const data = overrides[category]?.[id] || buildPostIds()[category][id];

  return typeof data === "function" ? data() : data;
};

/**
 * Returns all CMS posts within a given category.
 */
export const getPostsByCategory = async (category: PostCategoryId): Promise<CmsPost[] | undefined> => {
  const { overrides } = getDocsConfig().contextualElementTutorials;

  const categoryData = {
    ...buildPostIds()[category],
    ...(overrides[category] || []),
  };

  const data = await Promise.all(
    Object.values(categoryData).map((value) => {
      const articleId = typeof value === "function" ? value() : value;
      const getAllPostsAction = internalApi.endpoints.getCmsPost.initiate(articleId);
      return store.dispatch(getAllPostsAction);
    })
  );

  return data.reduce((list, { status, data }) => {
    return status === "rejected" || !data ? list : [...list, data];
  }, [] as CmsPost[]);
};

/**
 * Builds a list of all post IDs.
 * @returns
 */
export const buildPostIds = (): PostIds => {
  const isHkma = process.env.NEXT_PUBLIC_THEME_NAME?.startsWith("hkma");

  /** The ID of every tool currently active in the system. */
  const allToolIds = getConfig().featureSet.tools.map((tool) => tool.id);

  const postIds: PostIds = {
    glossary: {
      archetype: "70803fa4-f352-4d6e-adb8-db28ed2d241d",
      buildYear: "42b3be9c-1c85-4342-b6f1-12bd9fa24e38",
      heightAboveGround: "a628fc45-9388-4833-acd2-3ff323858efa",
      metrics: "353f6ef0-307b-4568-8781-9ce65437b954",
      riskBands: "afc46a8b-5b13-4212-898c-d3ed9c253e09",
      significantHazards: "1361da59-5525-4408-b3a1-5f04e64272ac",
    },
    guide: {},
    method: {
      methodFaqs: "d573503e-96af-4583-b5e5-8230d3ccaa25",
      // "temporarily" disabled while hkma review
      // geocodingExplanation: "geocoding-explanation",
      // ngfs: "ngfs",
    },
    test: {
      testPageRegular: "test-page-regular",
      testPageWide: "test-page-wide",
    },
  };

  if (isHkma) {
    postIds.guide.enteringLocationsAndAddresses = "entering-locations-and-addresses-hk";
  }

  if (allToolIds.indexOf("portfolioAnalysis") !== -1) {
    postIds.glossary.portfolioAnalysisResultsExplanation = "what-do-my-portfolio-analysis-results-mean";
  }

  return postIds;
};

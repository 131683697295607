import { Features } from "../../types/features";

const features: Features = {
  assetPreview: {
    title: "Asset Preview",
  },
  changeLog: {
    title: "Changelog",

    categories: {
      added: "Added",
      changed: "Changed",
      fixed: "Fixed",
      removed: "Removed",
      security: "Security",
      deprecated: "Deprecated",
    },
  },
  inputModules: {
    anaBatch: {
      title: "BLAZE",
      subtitle: "Process a list of assets through BLAZE.",
    },
    singleRiskBands: {
      title: "Single Risk Bands",
      subtitle: "Add a single asset's risk bands to the library.",
    },
    singleAsset: {
      title: "Single Asset",
      subtitle: "Add a single full asset to the library.",
    },
    multipleAssets: {
      title: "Multiple Assets",
      subtitle: "Add multiple assets to the library.",
    },
  },
  support: {
    title: "Contact Support",
    subtitle: "",
    form: {
      firstname: "First Name",
      lastname: "Last Name",
      username: "Username",
      organisation: "Organisation",
      xdi_support_issue_type: "Support Request Category",
      message: "How can we help?",
      fileUpload: "File upload",
      button: "Submit",
      successMessage: {
        title: "Thank you for your submission.",
        description:
          "Our customer support team will review your support query and contact you via the email address provided. Please allow 2-3 business days for a response.",
      },
      errorUploadMessage: "Something went wrong while attaching your files. Please try again.",
      errorMessage: "Something went wrong while submitting your enquiry.",
    },
  },
  feedback: {
    title: "Provide Feedback",
    subtitle: "",
    form: {
      firstname: "First Name",
      lastname: "Last Name",
      username: "Username",
      organisation: "Organisation",
      xdi_support_issue_type: "Support Request Category",
      message: "How can we improve your Hub experience?",
      button: "Submit",
      successMessage: {
        title: "Your feedback was submitted.",
        description: "Our customer support team will review your feedback and contact you with any further questions.",
      },
      errorUploadMessage: "Something went wrong while attaching your files. Please try again.",
      errorMessage: "Something went wrong while submitting your enquiry.",
    },
  },
};

export default features;

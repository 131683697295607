import { HacsSettings } from "@/features/hacs";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export interface CmsPost {
  id: string;
  title: string;
  body: string;
  url: string;
  slug: string;
}

export const internalApi = createApi({
  reducerPath: "internal",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/",
  }),
  endpoints: (build) => {
    return {
      /**
       * Retrieves Document360 article with given ID.
       */
      getCmsPost: build.query<CmsPost, string>({
        query: (articleId: string) => {
          return {
            url: "document360/get-article",
            params: {
              article_id: articleId,
            },
          };
        },
      }),

      /**
       * Retrieves a Portfolio Analysis download link.
       */
      getPortfolioAnalysisDownloadLink: build.query<
        {
          link: string;
        },
        {
          filename: string;
          folderId: string;
          type: string;
        }
      >({
        query: (body) => {
          return {
            method: "POST",
            url: "portfolio-analysis/download",
            body,
          };
        },
      }),

      /**
       * Uploads and triggers a Portfolio Analysis run.
       */
      uploadPortfolioAnalysis: build.query<
        {
          folderId: string;
          taskArn: string;
        },
        {
          body: any;
          settings: HacsSettings;
          uploadSettings: {
            lineCount?: number;
            coreCount?: number;
          };
        }
      >({
        query: (body) => {
          return {
            method: "POST",
            url: "portfolio-analysis/upload",
            body,
          };
        },
      }),

      /**
       * Sends product usage to HubSpot.
       */
      logProductUsage: build.query<
        void,
        {
          organisation: string;
          product: string;
          username: string;
          amount: number;
          pipelineId: string;
          pipelineStage: string;
          domain: string;
          portfolioId?: string;
        }
      >({
        query: ({ organisation, product, portfolioId, amount, pipelineId, pipelineStage, username, domain }) => {
          return {
            method: "POST",
            url: "usage-tracking",
            body: {
              organisation,
              product,
              portfolio_id: portfolioId,
              username,
              amount,
              pipeline_id: pipelineId,
              pipeline_stage: pipelineStage,
              domain,
            },
          };
        },
      }),
    };
  },
});
